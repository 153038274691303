<template>
    <div class="root-wrap">
        <div class="header-wrap">
        </div>
        <div class="body-wrap">
            <div class="fill-box" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="40" style="overflow: scroll;">
                <van-empty v-if="!list || list.length < 1" description="您还未产生任何交易记录" :image="require('@/assets/images/user/recharge-empty.png')"></van-empty>
                <div v-else class="list-wrap">
                    <div v-for="month, index in prettyList" :key="index">
                        <div class="month--title">{{ month.month }}</div>
                        <div class="month-content">
                            <div class="month--day-wrap">
                                <div v-for="day, idx in month.children" :key="idx">
                                    <div class="fx-center month--day-item">
                                        <div class="month--day">{{ day.dayStr}}</div>
                                        <div class="month--week">{{day.weekStr}}</div>
                                    </div>
                                    <div v-for="j, i of day.length - 1" :key="i" class="month--day-item"></div>
                                </div>
                            </div>
                            <div class="fx-fill">
                                <div v-for="item, idx in month.items" :key="idx" class="transaction-item">
                                    <div class="trs--desc">{{ item.typeDesc }}</div>
                                    <div class="trs--remark">{{ item.remark }}</div>
                                    <div class="trs--balance">余额 {{ item.balance | formatMoney}}</div>
                                    <div class="trs--amount" :class="item.amount > 0 ? 'red' : 'green'">{{item.amount | formatMoney}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getTransactionList } from '@api/recharge-request'
import { dateFormatter } from '@/libs/date-utils'
import { find } from 'lodash'
import pagination from '@/components/mixins/pagination';
export default {
    mixins: [pagination],
    data() {
        return {
            list: [],
            cardId: this.$route.query.cardId,
        }
    },
    filters: {
        formatMoney(val) {
            return Number(val).toFixed(2)
        },
    },
    computed: {
        prettyList() {
            let result = [];
            let month, day, dayStr, weekStr;
            for (let item of this.list) {
                month = dateFormatter(item.addTime, 'MM月/yyyy');
                day = dateFormatter(item.addTime, 'yyyy-MM-dd');
                dayStr = dateFormatter(item.addTime, 'dd');
                weekStr = dateFormatter(item.addTime, 'W');

                let monthObj = find(result, ['month', month]);
                if (!monthObj) {
                    result.push({
                        month,
                        children: [{
                            day,
                            dayStr,
                            weekStr,
                            length: 1
                        }],
                        items: [item]
                    })
                } else {
                    monthObj.items.push(item);
                    let dayObj = find(monthObj.children, ['day', day]);
                    if (!dayObj) {
                        monthObj.children.push({
                            day,
                            dayStr,
                            weekStr,
                            length: 1
                        })
                    } else {
                        ++dayObj.length
                    }
                }
            }
            return result;
        }
    },
    created() {
        this.getTransactionList().then(() => {
            this.$nextTick(() => {
                this.loadMore();
            })
        });
    },
    methods: {
        test() {
            console.log(this.prettyList, '结果')
        },
        loadMore() {
            if (this.pagination.next) {
                ++this.pagination.pageNo;
                this.getTransactionList();
            }
        },
        getTransactionList() {
            return new Promise((resolve) => {
                let params = {
                    pageSize: 10,
                    pageNo: this.pagination.pageNo,
                    id: this.cardId
                }
                getTransactionList(params).then((res) => {
                    this.list = this.list.concat(res.list);
                    this.copyPagination(res);
                    resolve();
                })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.body-wrap {
	position: relative;
	flex: 1;
}

.list-wrap {
	background-color: #fff;
	.month--title {
		background-color: #e4e7ed;
		border: 1px solid #c0c4cc;
		padding: 5px 10px;
		color: #333;
	}

	.month--day-wrap {
		padding: 0px 16px;
		background-color: #eff1f8;

		.month--day-item {
			height: 65px;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.month--day {
			font-size: 15px;
			font-weight: 700;
			color: #606266;
		}

		.month--week {
			font-size: 12px;
			color: $tips-color;
			margin-top: 5px;
		}
	}

	.month-content {
		display: flex;
		flex-direction: row;

		.transaction-item {
			padding: 4px 16px;
			height: 65px;
			border-bottom: 1px solid $border-color;
			display: grid;
			grid-template-columns: 1fr 1fr;
			align-items: center;
		}

		.trs--desc {
			font-size: 15px;
			color: #333;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.trs--remark {
			font-size: 14px;
			color: #333;
			text-align: right;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.trs--balance {
			color: $tips-color;
			font-size: 13px;
		}

		.trs--amount {
			text-align: right;
			font-size: 16px;
			font-weight: 700;
			&.green {
				color: $type-success;
			}

			&.red {
				color: $active-color;
			}
		}
	}
}
</style>
